<template>
  <!-- 监控视屏 -->
  <div class="videopage">
    <div class="back-but">
      <el-button type="info" icon="el-icon-back" @click="clickblack"
        >上一页</el-button
      >
    </div>
    <div>
      <ul class="winvideo">
        <li>
          <iframe
            :src="
              `https://open.ys7.com/ezopen/h5/iframe_se?url=${urlArr}&audio=1&accessToken=` +
                token
            "
            width="80%"
            height="450"
            id="iframe"
            allowfullscreen
            v-if="token"
          >
          </iframe>
        </li>
      </ul>
      <div class="butdiv">
        <ul class="butlist">
          <li v-for="(item, index) in ultlist" :key="index">
            <el-button type="info" @click="clickBut(item)">
              {{ item.regionName }}</el-button
            >
          </li>
        </ul>
        <div class="butPage">
          <el-pagination
            @current-change="handleCurrentChange"
            background
            layout="prev, pager, next"
            :page-size="perPage"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMonitoringFun, getToken } from '@/api/homePage/right.js'
export default {
  data() {
    return {
      // 装入地址的数组
      urlArr: null,
      // 地址列表数据
      ultlist: [],
      token: null,
      page: 1,
      perPage: 16,
      total: 0,
    }
  },
  computed: {
    insNo() {
      return this.$route.query.insNo
    },
  },
  mounted() {
    this.getDeviceList()
  },
  methods: {
    // 获取到摄像头列表
    async getDeviceList() {
      const res = await getMonitoringFun({
        page: this.page,
        perPage: this.perPage,
        institutionNo: this.insNo,
      })
      const token = await getToken(this.insNo)
      if (token.code == 0) {
        this.token = token.data
      }
      if (res.code == 0) {
        if (res.data.length == 0) {
          this.urlArr = null
        } else {
          this.ultlist = res.data
          this.total = res.total
          this.urlArr = res.data[0].url
        }
      }
    },
    handleCurrentChange(val) {
      this.page = val
      this.getDeviceList()
    },
    // 点击按钮
    clickBut(item) {
      this.urlArr = item.url
    },
    // 返回上一页
    clickblack() {
      this.$router.push({
        path: '/home',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #a6a9ad;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #999999;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  border: none !important;
}
.videopage {
  min-height: 100vh;
  background: url(./bigbg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  background-size: cover;
}
.back-but {
  line-height: 3rem;
  padding-left: 1.5rem;
}
.winvideo {
  display: flex;
  flex-wrap: wrap;
  height: 16.1rem;
  margin-bottom: 4rem;
}
.winvideo li {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  width: 70%;
  display: flex;
  justify-content: center;
}
.fen-page {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.butdiv {
  height: 21.5rem;
  position: relative;
  .butPage {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}
.butdiv .butlist {
  width: 100%;
  display: grid;
  grid-column-gap: 8rem;
  grid-row-gap: 3rem;
  grid-template-columns: auto auto auto auto;
  background-color: transparent;
  padding: 0rem;
}
.butdiv .butlist > li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
}
.winvideo {
  display: flex;
  justify-content: center;
}
</style>
